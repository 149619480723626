<template>
    <div class="confirm">
        <div class="confirm-extra">
            <div class="confirm-bubble">
                
                 <strong>PRESENTES</strong> <br><br>
                    
                    Aos que quiserem contribuir para: <br><br>
                
                    - A nossa viagem de lua-de-mel <br>
                

                    - Fase inicial da nossa futura casa <br>
                
                       <br>
                            
                       Agradecemos que o façam para o IBAN  <br>
                <small>
                    PT50 0269 0179 0020 4281 927 20

                </small>

            </div>    
        </div>    
        <div id="confirmacao" class="form">
            <form ref="form" @submit.prevent="sendEmail">
                <label>Nome</label>
                <input type="text" name="user_name">
                <div class="input">
                    <input type="radio" value="Estarei presente" name="user_casamento">
                    <label for="">Estarei presente</label>
                </div>
                <div class="input">
                    <input type="radio" value="Lamentavelmente, não posso estar presente" name="user_casamento">
                    <label>Lamentavelmente, não posso estar presente</label>
                </div>
                <div class="input">
                    <input type="checkbox" value="interessado" name="user_shuttle">
                    <label for="">Estou interessado no serviço de shuttle</label>
                </div>
                <label>Email</label>
                <input type="email" name="user_email">
                <label>Telefone</label>
                <input type="text" name="user_telefone">
                <label>Mensagem</label>
                <textarea name="message"></textarea>
                <input type="submit" value="Enviar Confirmação">
            </form>
            <div v-if="erro" class="erro">
                {{  erro  }}
            </div>
        </div>


    </div>
</template>
<script>
import emailjs from '@emailjs/browser';
export default {
  name: 'ContactUs',
data() {
    return {
        erro: '',
    };
},
  methods: {
    sendEmail() {
      emailjs.sendForm('service_l4yng88', 'template_y3ejtuj', this.$refs.form, 'V58jMz4PzzDIi81BR')
        .then((result) => {
            this.erro = 'Confirmação enviada!', result.text;
        }, (error) => {
            this.erro = 'Ocorreu um erro no envio', error.text;
        });
    },
    }
}
</script>
<style scoped lang="scss">
.confirm {
  padding-top: 80vh;
  height: 180vh;
  background-size: cover;
  background-attachment: fixed;
  background-image: url('../assets/fotos/380A9111-min.jpg');    
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 740px) {
    display: block;
    height: auto;
    padding: 10px 0;
  }
  &>div {
    margin: 20px;
  }
  .form {
    background: white;
    max-width: 500px;
    border-radius: 10px;
    padding: 25px;
    .erro {
        margin-top: 20px;
    }
    .input {
        display: flex;
        margin-bottom: 15px;
        input {
            margin-right: 5px;
        }
    }
    input {
        &[type=text], &[type=email] {
            display: block;
            border: 1px solid #9D584B;
            margin-bottom: 15px;
        }
    }
    textarea {
        border: 1px solid #9D584B;
    }
    label {
        font-size: 14px;
        color: #9D584B;
        display: block;
    }
    [type="submit"] {
        display: block;
        background: #9D584B;
        border: 1px solid #9D584B;
        color: white;
        margin-top: 20px;
        padding: 10px;
        &:hover {
            background: white;
            color: #9D584B;
        }
    }
  }
    &-extra {
        max-width: 430px;
        padding: 40px;
        display: flex;
        justify-content: space-between;
        background: white;
        color: black;
        strong {
            color: #9D584B;
        }
    }
}


</style>