<template>
  <div class="home" @scroll="handleScroll">
    <nav>
      <img class="titulo" src="../assets/icons/title.svg">
      <div class="menu">
        <a href="#info">
          informação
        </a>
        <a href="#alojamento">
          alojamento
        </a>
        <a href="#confirmacao">
          confirmacao
        </a>
      </div>
      <img style="opacity: 0;" class="titulo two" src="../assets/icons/title.svg">
      <img class="estrela" src="../assets/icons/estrela.svg" :style="'transform: rotate(' + spin + 'deg)'">
    </nav>
    <Fotos/>
    <Info id="info"/>
    <div class="foto um" style="">
    </div>
    <Alojamento id="alojamento" />
    <Confirm></Confirm>
  </div>
</template>

<script>
// @ is an alias to /src
import Fotos from '@/components/Fotos.vue'
import Info from '@/components/Info.vue'
import Alojamento from '@/components/Alojamento.vue'
import Confirm from '@/components/Confirm.vue'

export default {
  name: 'HomeView',
  components: {
    Fotos,
    Info,
    Alojamento,
    Confirm
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.scrollY;
    },
  },
  computed: {
    spin() {
      return this.scrollTop * 0.15
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
<style scoped lang="scss">

nav {
  color: white;
  padding: 15px 20px;
  background: #9D584B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
  @media only screen and (max-width: 740px) {
    display: block;
    text-align: center;
  }
  .menu {
    display: flex;
    justify-content: center;
    a {
      color: white;
      margin: 0 10px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      @media only screen and (max-width: 740px) {
          margin-top: 10px;
          font-size: 13px;
      }
    }
  }
  .titulo {
    max-height: 40px;
    @media only screen and (max-width: 740px) {
      &.two {
        display: none;
      }
    }
  }
  .estrela {
    position: absolute;
    right: -4%;
    top: -100%;
    width: 200px;
      @media only screen and (max-width: 740px) {
        display: none;
      }
    }
}
.foto {
  height: 100vh;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 740px) {
    height: 400px;
    width: 100%;
  }
  &.um {
    background-image: url('../assets/fotos/380A0314-min.jpg');    
  }
  &.dois {
    background-image: url('../assets/fotos/380A9111-min.jpg');    
  }
}


</style>
