<template>
    <div class="info">
        <div class="convite desktop">
            <img class="estrela" src="../assets/icons/convite.svg">
            <div class="info-dresscode">
                <strong>
                DRESS CODE <br>            
                </strong>  
                Fato de qualquer cor, que não azul escuro;             
                <br>
                Apesar de ser setembro e no Algarve, já vamos estar no outono pelo que recomendamos que traga algo que o/a proteja do frio
                <br>
            </div>
        </div>
        <div class="convite mobile">
            <div class="info-block">
                <strong>CERIMÓNIA</strong> 
                <a class=" " target="_blank" href="https://goo.gl/maps/wQSgEprBZvv7WSPZ6">
                    Igreja Matriz de Estômbar <br><br>
                </a>
                <strong>COPO DE ÁGUA</strong> 
                <a class=" " target="_blank" href="https://goo.gl/maps/PrnNLoyDXynBGngK8">
                    Solar do Paço - Algoz <br><br>
                </a>
                <strong>
                    DATA E HORA
                </strong>
                23 de Setembro 2023, 15h30 
            </div>
            <div class="info-block">
                <strong>CONFIRMAÇÃO ATÉ</strong>
                30 de Junho 2023 <br><br>
                <strong>JOANA ANDREZ</strong>
                963403635 <br><br>
                <strong>JOÃO MOREIRA</strong>
                916946395 
            </div>            
            <div class="info-block">
                <strong>
                DRESS CODE <br>            
                </strong>  
                Fato de qualquer cor, que não azul escuro;             
                <br>
                Apesar de ser setembro e no Algarve, já vamos estar no outono pelo que recomendamos que traga algo que o/a proteja do frio
                <br>
            </div>
            <a target="_blank" href="https://goo.gl/maps/wQSgEprBZvv7WSPZ6"></a>
            <a target="_blank" href="https://goo.gl/maps/PrnNLoyDXynBGngK8"></a>
        </div>
    </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  components: {

  }
}
</script>
<style lang="scss">

.info {
    background: #9D584B;
    color: white;
    padding: 40px 0;
    .convite {
        position: relative;
        width: 100%;
        &.desktop {
            @media only screen and (max-width: 740px) {
                display: none;
            }            
        }
        &.mobile {
            display: none;
            @media only screen and (max-width: 740px) {
                display: block;
            }
            .info-block {
                background: white;
                width: 90%;
                margin: 20px auto;
                color: #9D584B;
                padding: 20px;
                a {
                    color: #9D584B;
                }
                strong {
                    display: block;
                    color: black;
                }
            }
        }
        img {
            display: block;
            width: 90%;
            margin: 40px auto;
        }
        .map {
            position: absolute;
            width: 30%;
            height: 30px;
            display: block;
            opacity: 0.5;
            left: 7%;
            background-size: auto 100%;
            background-position: left top;
            background-repeat: no-repeat;
            &:hover {
                background-image: url('../assets/icons/pin.png'); 
            }
            &.one {
                bottom: 34%;
            }
            &.two {
                bottom: 21.5%;
            }
        }
    }
    &-dresscode {
        strong {
            color: black;
            display: block;
            margin-bottom: 10px;
        }
        position: absolute;
        color: #9D584B;
        bottom: 4%;
        left: 10%;
        width: 40%;
        font-size: 0.9vw;
    }
}

</style>