<template>
    <div class="fotos">
      <slick
        :options="slickOptions"
      >
            <img src="../assets/fotos/0809ba20-75f5-4256-bf28-28670d501b11-min.jpg">
            <img src="../assets/fotos/IMG_2944-min.jpeg">
            <img src="../assets/fotos/IMG_2978-min.jpeg">
            <img src="../assets/fotos/IMG_4441 2-min.jpeg">
            <img src="../assets/fotos/IMG_4868-min.jpeg">
            <img src="../assets/fotos/IMG_4899-min.jpeg">
            <img src="../assets/fotos/IMG_7276-min.jpeg">
            <img src="../assets/fotos/IMG_9599-min.jpeg">
            <img src="../assets/fotos/380A0259-min.jpg">
            <img src="../assets/fotos/380A0314-min.jpg">
            <img src="../assets/fotos/380A8172-min.jpg">
            <img src="../assets/fotos/380A8732-min.jpg">
            <img src="../assets/fotos/380A8836-min.jpg">
            <img src="../assets/fotos/380A9111-min.jpg">
            <img src="../assets/fotos/380A9420-min.jpg">
      </slick>
    </div>
</template>
<script>
import Slick from 'vue-slick';
export default {
  name: 'HelloWorld',
  components: {
    Slick 
  },
    data() {
        return {
            slickOptions: {
                slidesToShow: 3,
                centerMode: true,
                autoplay: true,
                variableWidth: true,
                // arrows: false
            },
        };
    },}
</script>
<style lang="scss">

.fotos {
  // display: flex;
  // overflow: scroll;
  img {
    height: 600px;
      @media only screen and (max-width: 740px) {
        height: 400px;
      }
  }  
}
.slick {
  &-next {
    right: 3vw;
  }
  &-prev {
    left: 3vw;
    z-index: 80;
  }
}


</style>