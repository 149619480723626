<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {

};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;600&display=swap');
@import 'slick-carousel/slick/slick.css';


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Source Code Pro', monospace;
}

.mobile {
  display: none;
  @media only screen and (max-width: 740px) {
    display: block;
  }
}
.desktop {
  @media only screen and (max-width: 740px) {
    display: none;
  }
}
</style>
