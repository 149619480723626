<template>
    <div class="alojamento">
      <div class="linha">
        <div class="bloco">
          <strong>SOLAR DO PAÇO</strong> <br><br>
          <img class="mobile" src="https://static.wixstatic.com/media/69c7a8_5ab29b319a6142d481510ef34253657c~mv2.jpg/v1/fit/w_2500,h_1330,al_c/69c7a8_5ab29b319a6142d481510ef34253657c~mv2.jpg">
          O Solar do Paço disponibiliza 4 quartos duplos. <br>
          O valor é de 350€/noite com pequeno almoço incluído. <br>
          Caso pretenda aqui ficar deverá contactar o Solar através do email 
          <a href="geral@solarpacoalgarve.com">geral@solarpacoalgarve.com</a> ou do telefone <u>965 892 132</u> e fazer a sua reserva.
        </div>
        <div class="bloco">
          <img class="desktop" src="https://static.wixstatic.com/media/69c7a8_5ab29b319a6142d481510ef34253657c~mv2.jpg/v1/fit/w_2500,h_1330,al_c/69c7a8_5ab29b319a6142d481510ef34253657c~mv2.jpg">
        </div>
      </div>
      <div class="linha">
        <div class="bloco">
          <img class="desktop" src="https://www.colinahotels.com/cache/4a/d4/4ad44f0c2062d7194c3142a204584350.png">
          <img class="desktop" src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/03/e4/c2/f2/colina-dos-mouros.jpg?w=700&h=-1&s=1">
        </div>
        <div class="bloco">
          <strong>HOTEL COLINA DOS MOUROS</strong> <br><br>
          <img class="mobile" src="https://www.colinahotels.com/cache/4a/d4/4ad44f0c2062d7194c3142a204584350.png">
           Uma vez que o Solar tem um número reduzido de quartos, e para sua comodidade, 
           estabelecemos um acordo com o <a href="https://www.colinahotels.com/pt/hotel-colina-dos-mouros-em-silves/">Hotel Colina dos Mouros </a> em Silves.
           <br>
           Fica a aproximadamente <a href="https://www.google.com/maps/place/Hotel+Colina+dos+Mouros/@37.1873128,-8.4339929,15z/data=!4m2!3m1!1s0x0:0xa111245de4e09bf3?sa=X&ved=2ahUKEwiokPqWk4z_AhVCzaQKHTmlDz8Q_BJ6BAhgEAc">15 min de carro do Solar do Paço</a>. 
           <br>
           <br>
           Caso opte por aqui ficar, basta contactar o hotel por via telefónica ou email e utilizar o código promocional <b>"Joana&João"</b> ativo para reservas de estadias nas seguintes condições:
           <br>
            Válido para estadias entre os <b>dias 22 e 25 de Setembro 2023</b>.
           <br>
            Desconto de <b>15% sobre a melhor tarifa disponível</b> no website do hotel para reservas efetuadas até dia <b>30 de Junho de 2023</b>.
            O desconto será aplicado sobre a tarifa que o convidado optar.
            <br>
            <br>
            Atenção que no website não será possível a utilização do código, apenas ao contactar o Departamento de Reservas do hotel  através de email (<a href="mailto:reservations@colinahotels.com">reservations@colinahotels.com</a>) ou telefone (<u>282 012 651</u>) e estará sempre sujeita à disponibilidade do momento.
  
          </div>
      </div>
      <div class="linha bottom">
        <strong class="">
          <br>
          Como a festa será longa e por forma a não precisar de utilizar o carro no final da mesma, estamos a considerar um serviço de shuttle cujo objetivo será conduzir os convidados, que assim o desejarem, desde o hotel até à igreja, da igreja para o Solar e ao final do dia do Solar de volta ao Hotel.  
          <br>
          <br>
          Esta opção estará dependente do número de convidados interessados pelo que, caso esteja interessado, solicitamos que nos informe na <a href="#confirmacao">confirmação</a> <b>até ao dia 30/06/23</b>.
        </strong>
      </div>  
      <br><br>
      <br><br>
    </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  components: {
  }
}
</script>
<style scoped lang="scss">

.alojamento {
  padding-top: 40px;
}
.linha {
  display: flex;
  width: 80%;
  margin: 40px auto;
  color: #606060;
  @media only screen and (max-width: 740px) {
    display: block;
    width: 100%;
  }
  &.bottom {
    @media only screen and (max-width: 740px) {
      margin: 10px 0;
      padding: 0 15px;
    }
  }
  strong {
      color: #9D584B;
      a {
        color: #9D584B;
      }

  }
  .bloco {
    a {
      color: #606060;
    }
    padding: 15px;
    width: 50%;
    @media only screen and (max-width: 740px) {
      width: 100%;
    }
    img {
      width: 100%;
      @media only screen and (max-width: 740px) {
        margin-bottom: 15px;
      }
    }
  }

}


</style>